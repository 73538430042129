import React from "react";
import Pages from "../../../pages";
import { Navigation, PageLink } from "../../../lib";

export default class PublicCredits extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className="credits-header">
          <h1 className="xxxl-text fw-700 mb-16 text-center">Credits</h1>
          <p
            className="xl-text text-center line-height-double"
            style={{ maxWidth: 800, margin: "0 auto" }}
          >
            JewishU courses earn JewishU credits which can be redeemed at any
            time for <br />
            Learn n’ Grow, Learn n’ Give, or Learn n’ Earn cash value. Credits
            can also be saved up for a grand trip.
          </p>
        </div>
        <div className="credits-section mb-80">
          <h2 className="credits-section-title fw-700 text-center mb-16">
            Save up credits for a grand trip
          </h2>
          <p className="large-text text-center line-height-double mb-56">
            Or redeem credits when you want for Learn n’ Grow, Learn n’ Give, or
            Learn n’ Earn cash value.
          </p>
          <div
            className="flex flex-justify-space mobile-block"
            style={{ maxWidth: 624, margin: "0 auto" }}
          >
            <div className="text-center mobile-mb-40">
              <img
                className="mb-24"
                src="/images/credit-programs/learn-n-grow.svg"
                alt="Learn n' Grow"
              />
              <div className="flex flex-column flex-justify-space">
                <p className="xl-text fw-700">Learn n' Grow</p>
                <p className="accent-text-dark mt-16 line-height-double reward-description">
                  Use for approved Jewish programming or Judaica purchases
                </p>
                <p className="accent-text mt-16 fw-500">Most value</p>
              </div>
            </div>
            <div className="text-center mobile-mb-40">
              <img
                className="mb-24"
                src="/images/credit-programs/learn-n-give.svg"
                alt="Learn n' Give"
              />
              <div className="flex flex-column flex-justify-space">
                <p className="xl-text fw-700">Learn n' Give</p>
                <p className="accent-text-dark mt-16 line-height-double reward-description">
                  Donate to an approved charity of choice
                </p>
                <p className="accent-text mt-16 fw-500">Enhanced value</p>
              </div>
            </div>
            <div className="text-center mobile-mb-40">
              <img
                className="mb-24"
                src="/images/credit-programs/learn-n-earn.svg"
                alt="Learn n' Earn"
              />
              <div className="flex flex-column flex-justify-space">
                <p className="xl-text fw-700">Learn n' Earn</p>
                <p className="accent-text-dark mt-16 line-height-double reward-description">
                  Redeem as a gift card including Visa cash card
                </p>
                <p className="accent-text mt-16 fw-500">Great value</p>
              </div>
            </div>
          </div>
        </div>
        <div className="credits-section credits-section-accent-background text-center">
          <p className="xxl-text fw-600 mb-32">
            For more information sign in to your account
          </p>
          <PageLink
            className="btn btn-accent"
            to={Pages.account.login}
            query={{
              after: Navigation.locationURL,
            }}
          >
            Log In
          </PageLink>
        </div>
      </React.Fragment>
    );
  }
}
