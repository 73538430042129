import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { AuthActions, CampusesActions } from "../../state";
import Register from "./Register";

export default connect(
  function mapState(state) {
    return {
      ...state.registration,
      campuses: state.campuses,
      sys: state.sys,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators({
        ...AuthActions, 
        ...CampusesActions,
      }, dispatch),
    };
  },
)(Register);
