import React from "react";
import AccountPageWrapper from "./components/AccountPageWrapper.vm";
import GoogleIdentitySignin from "./components/GoogleIdentitySignin";
import Loader from "../../components/Loader";
import LoginRegisterHeader from "./components/LoginRegisterHeader";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "../../components/form/PhoneInput";
import Select from "../../components/form/Select";
import SelectSearch from "react-select";
import ValidatedInput from "../../components/ValidatedInput";
import { withAppInsights } from "../../lib/AppInsights";
import {
  getYearOptions,
  handleErrorMessage,
  handlePreventDefault,
  Navigation,
  PageURL,
  validateEmail,
  validatePassword,
} from "../../lib";
import Pages from "../../pages";

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

class Register extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      registration: {
        campusID: null,
        email: "",
        firstName: "",
        graduationYear: null,
        lastName: "",
        password: "",
        passwordConfirmation: "",
        phone: "",
        phoneCountryID: null,
      },
      error: "",
      phoneValidationError: false,
      recaptchaLoaded: false,
      recaptchaError: false,
      showFormValidation: false,
      submitFormAttempted: false,
    };

    this._recaptchaRef = React.createRef();
    this._isMounted = false;

    this.graduationYears = getYearOptions(
      new Date().getFullYear(),
      new Date().getFullYear() + 15,
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.actions.getCampuses();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  isIncompleteRegistration = () => {
    const {
      registration: {
        campusID,
        email,
        firstName,
        graduationYear,
        lastName,
        password,
        passwordConfirmation,
        phone,
      },
      phoneValidationError,
    } = this.state;

    return (
      !campusID ||
      !email ||
      !firstName ||
      !graduationYear ||
      !lastName ||
      !password ||
      !phone ||
      password !== passwordConfirmation ||
      phoneValidationError ||
      !validateEmail(email) ||
      !validatePassword(password)
    );
  };

  onChangeRegistration = (name, value, other = {}) =>
    this.setState({
      registration: { ...this.state.registration, [name]: value, ...other },
    });

  onChangeRegistrationEvt = (e) => {
    this.onChangeRegistration(e.target.name, e.target.value);

    if (this.state.error) {
      this.setState({ error: null });
    }
  };

  onBasicRegistration = handlePreventDefault(() => {
    this.setState(
      {
        error: null,
        showFormValidation: true,
        submitFormAttempted: true,
      },
      async () => {
        if (this.isIncompleteRegistration()) {
          return;
        }

        this._recaptchaRef.current.reset();
        this._recaptchaRef.current.execute();
      },
    );
  });

  onCaptchaExecution = async (recaptchaToken) => {
    const {
      pageRoute: {
        query: { after },
      },
    } = this.props;
    const { registration } = this.state;

    await this.props.actions.doBasicRegistration(registration, recaptchaToken);

    if (!this.props.error && this.props.data) {
      const registrationPayload = this.props.data;
      const confirmRegistrationURL = PageURL.to(
        Pages.account.confirm.path,
        null,
        {
          after,
          id: registrationPayload.id,
        },
      );
      Navigation.go(confirmRegistrationURL, {
        confirmRegistrationEmail: registrationPayload.email,
        registrationValidationMessage: registrationPayload.validationMessage
          ? `Your account was created. ${registrationPayload.validationMessage}`
          : "",
      });
    } else {
      this.setState({
        error: this.props.error,
        password: "",
        passwordConfirmation: "",
        showFormValidation: false,
      });
    }
  };

  // onFacebookRegistration = async fbUser => {
  //   await this.props.actions.doFacebookRegistration(fbUser);

  //   if (this.props.error) {
  //     this.setState({
  //       error: this.props.error
  //     });
  //   }
  // };

  onGoogleRegistration = async () => {
    if (this.props.error) {
      this.setState({
        error: this.props.error,
        showFormValidation: false,
        submitFormAttempted: true,
      });
    }
  };

  render() {
    const {
      campuses: { error: campusesError, data: campuses },
      pageRoute: { query },
      loading,
      sys: { countries = [] },
    } = this.props;

    const {
      registration: {
        campusID,
        email,
        firstName,
        graduationYear,
        lastName,
        password,
        passwordConfirmation,
        phone,
        phoneCountryID,
      },
      error,
      phoneValidationError,
      recaptchaLoaded,
      recaptchaError,
      showFormValidation,
      submitFormAttempted,
    } = this.state;

    const campusOptions =
      campuses
        ?.sort((c1, c2) =>
          c1.name.toLowerCase().localeCompare(c2.name.toLowerCase()),
        )
        .map((ca) => ({
          label: ca.name,
          value: ca.id,
        })) || [];

    return (
      <AccountPageWrapper logoLinkPage={Pages.main.home}>
        <LoginRegisterHeader pageLinkQuery={query} />

        <p className="mt-24 mb-24 text-center medium-text fw-700 line-height-double create-account-text">
          Create a Chabad on Campus Account and have one convenient login for
          our family of programs.
        </p>

        <div className="login-btns">
          <GoogleIdentitySignin
            onSubmit={this.onGoogleRegistration}
            register={true}
          />
        </div>
        <p className="login-options-text fw-700 medium-text">or</p>
        <p className="mt-24 mb-24 text-center medium-text">
          Create account using your email address
        </p>
        <form className="account-form register-form">
          <div className="register-form-grid">
            <ValidatedInput
              label="First Name"
              name="firstName"
              onChange={this.onChangeRegistrationEvt}
              required={true}
              showRequired={showFormValidation}
              value={firstName}
            />
            <ValidatedInput
              label="Last Name"
              name="lastName"
              onChange={this.onChangeRegistrationEvt}
              required={true}
              showRequired={showFormValidation}
              value={lastName}
            />
            <ValidatedInput
              label="Email"
              name="email"
              onChange={this.onChangeRegistrationEvt}
              required={true}
              showRequired={showFormValidation}
              showValidation={showFormValidation}
              validate={(email) => validateEmail(email)}
              validationMessage="Invalid email address"
              value={email}
            />
            <div
              className={`input-container ${
                phoneValidationError || (showFormValidation && !phone)
                  ? "error"
                  : ""
              }`}
            >
              <label>Phone</label>
              <PhoneInput
                countries={countries || []}
                countryId={phoneCountryID}
                error={phoneValidationError}
                name="phone"
                onChange={(phone, phoneCountryID) => {
                  this.onChangeRegistration("phone", phone, { phoneCountryID });
                }}
                validate={(isValid) =>
                  this.setState({ phoneValidationError: !isValid })
                }
                value={phone}
              />
            </div>
            <div>
              <ValidatedInput
                input={
                  <SelectSearch
                    classNamePrefix="input-container-select"
                    isClearable={true}
                    isSearchable={true}
                    options={campusOptions}
                    placeholder="Select School"
                  />
                }
                label="School"
                onChange={(campus) => {
                  console.log("change", campus);
                  this.onChangeRegistration("campusID", campus?.value || null);
                }}
                required={true}
                showRequired={showFormValidation}
                value={
                  campusID
                    ? campusOptions.find((c) => c.value === campusID)
                    : null
                }
              />
              {!!campusesError && (
                <span className="error-message mt-4 school-error-message">
                  We could not retrieve schools information. Please refresh the
                  page.
                </span>
              )}
            </div>
            <ValidatedInput
              input={
                <Select
                  clearable={true}
                  options={this.graduationYears.map((year) => ({
                    value: year,
                    label: year,
                  }))}
                  placeholder="Select Year"
                  searchable={true}
                />
              }
              onChange={this.onChangeRegistration}
              name="graduationYear"
              value={graduationYear}
              label="Graduating Year"
              required={true}
              showRequired={showFormValidation}
            />
            <ValidatedInput
              input={<input type="password" />}
              label="Password"
              name="password"
              onChange={this.onChangeRegistrationEvt}
              required={true}
              showRequired={showFormValidation}
              showValidation={showFormValidation}
              validate={(pw) => validatePassword(pw)}
              validationMessage="Password must be at least 8 characters long, and must contain an uppercase and lowercase letter and a digit."
              value={password}
            />
            <ValidatedInput
              input={<input type="password" />}
              label="Confirm Password"
              name="passwordConfirmation"
              onChange={this.onChangeRegistrationEvt}
              required={true}
              showRequired={showFormValidation}
              showValidation={showFormValidation}
              validate={(pwConfirmation, pw) => pwConfirmation === pw}
              validationMessage="Passwords do not match"
              validationValue={password}
              value={passwordConfirmation}
            />
          </div>
          <ReCAPTCHA
            asyncScriptOnLoad={({ loaded: recaptchaLoaded }) => {
              if (this._isMounted) {
                this.setState({ recaptchaLoaded });
              }
            }}
            badge="bottomleft"
            onChange={this.onCaptchaExecution}
            onErrored={() => {
              if (this._isMounted) {
                this.setState({ recaptchaError: true });
              }
            }}
            ref={this._recaptchaRef}
            sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
            size="invisible"
          />

          {!loading ? (
            <React.Fragment>
              <button
                className="btn btn-large btn-fullWidth btn-accent mt-16"
                disabled={!recaptchaLoaded || recaptchaError}
                onClick={this.onBasicRegistration}
                type="submit"
              >
                Create Account
              </button>

              <div className="error-message-container mt-4">
                <span className="error-message center">
                  {showFormValidation && this.isIncompleteRegistration()
                    ? "Please complete required/incomplete fields"
                    : !!error && submitFormAttempted
                    ? handleErrorMessage(
                        error,
                        "There was an error creating your account. Please try again.",
                      )
                    : ""}
                </span>
              </div>

              {!!recaptchaError && (
                <div className="error-message-container mt-4">
                  <span className="error-message center">
                    Recaptcha validation failed. Please try reloading the page
                    before attempting submission.
                  </span>
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className="btn-large-loader btn-large mb-32">
              <Loader />
            </div>
          )}
        </form>
      </AccountPageWrapper>
    );
  }
}

export default withAppInsights(Register);
